//colors
$primary: #FFBE18;
$secondary: #45F882;
$litegray: #6A6A6A;
$lite: #F0F2F5;
$linear: linear-gradient(225deg, #ffdb33 0.42%, #ffdb33 86.12%);
$themeBlack: #293333;
$orange : #fde428;



$gray: rgba(0, 0, 0, 0.5);
$themeLight: rgba(41, 51, 51, 0.7);
$black: #000000;
$liteblack: #F2F2F2;


$green: rgb(97, 229, 156);

$red: #E21213;

$purple: #7F56D9;
$litepurple: #F6F7FF;

$blue: #165397;

$orange: #FBA628;
$liteorange: #fff7ea;

$darkgreen: #35C5BE;
$litegreen: #F6FEFE;










$color-petrol: #549B8C;
$color-diesel: #BD9B16;

$dark: #212121;
$light: #efefef;
$color: #b3e5fc;


// fonts

$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Montserrat', sans-serif;


// general

$radius: .4rem;


body {
    background-color: white;
    font-family: $font-primary;
}


ul {
    padding-left: 0;
}

a {
    text-decoration: none;
}
