.countdownlist {
    margin: 30px 0;

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 50px;

        li {
            span {
                font-size: 32px;
                font-weight: bold;
            }

            p {
                margin: 0;
                color: #007cc3;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
}