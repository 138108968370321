.offcanvas.offcanvas-end.show {
  .accordion {
    border: transparent;

    .accordion-item:last-of-type .accordion-button.collapsed {
      border-radius: .8rem;
    }

    .accordion-item {
      border: transparent;

      .accordion-header {
        padding: .3rem 0;


        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        .accordion-button:not(.collapsed) {
          background-color: #cfe3fe;
        }

        .accordion-button:not(.collapsed),
        .accordion-button,
        button {
          font-size: .9rem;
          border-radius: .8rem;
          color: rgb(0, 0, 0);
          font-weight: 500;

          &:hover,
          &:focus,
          &:active {
            background-color: #52bdfb73;

          }

        }
      }

      .accordion-collapse.collapse.show,
      .accordion-item:last-of-type .accordion-collapse,
      .accordion-collapse.collapsing,
      .accordion-collapse.collapse {
        .accordion-body {
          padding: 4px 10px;

          .sub-menu {
            list-style: none;
            margin: 0;

            a {
              position: relative;
              display: flex;
              align-items: center;
              padding: .8rem 1rem;
              font-size: .9rem;
              border-radius: .8rem;
              color: black;

              &:hover,
              &:focus,
              &:active,
              &:target {
                background-color: #52bdfb73;

              }
            }
          }
        }
      }
    }
  }
}



.btn-close {
  z-index: 50;
}

.toggle-side-menu {
  display: none;
}

@media screen and (max-width : 991px) {
  .top-link {
    display: none;
  }

  .toggle-side-menu {
    display: block;
    z-index: 10;

    a {
      i {
        color: black;
        font-size: 30px !important;
      }
    }
  }

}