.simple-section {
    padding: 70px;
}

.title {
    font-size: 28px;
    margin-bottom: 35px;
    color: #fff;
}

.section-title {

    .title {
        background: linear-gradient(180deg, #fff 0%, $secondary 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 50px;
        margin-bottom: 0;
        font-weight: 600;
    }

    span {
        color: $primary;
    }
}
.section-detail{
    color: #fff;
}

.text-primary{
    color: $primary !important;
}
#overlay {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(0%);
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1;
    pointer-events: none;
    animation: slide-in-left 1s forwards;
}

@keyframes slide-in-left {
    1% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media screen and (max-width : 767px) {
    

    #body {
        width: 100%;
        overflow-x: hidden;
    }

    .section-title {
        font-size: 20px;
        font-weight: bold;

        span {
            padding-left: 0px;
        }
    }

    .section-title {

        .title {
            font-size: 35px;
        }
    }

    .simple-section {
        padding: 10px;
        width: 100%;
        overflow: hidden;
    }

    .section-detail {
        text-align: center;
        padding-top: 20px;
        font-size: .9rem;
        margin-bottom: 20px;
    }
}