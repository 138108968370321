.icon-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 11px 21px;
    width: 294px;
    gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    img {
        width: 60px;
        height: 60px;
    }

    h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin: 0;
        color: $gray;
    }
}
