body {
	font-family: $font-primary;
	//padding-top: 126px;
	top: 0 !important;
	background-color: rgb(0, 0, 0);
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $secondary;
	}
}


// @media screen and (max-width: 767px) {
// 	body {
// 		padding-top: 4rem;
// 	}
// }