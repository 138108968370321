.nav-tabs {
    justify-content: start;
    display: flex;
    border-bottom: none;

    .nav-item {
        color: black !important;

        .nav-link {
            border-color: transparent;
            background: rgb(255, 255, 255);

            border: 1px solid transparent;
            color: rgb(0, 0, 0);
            border-radius: 20px;
            margin: 10px;
        }

        &:focus,
        &:active {
            background: transparent;
            border-radius: 1px;
        }

        .active {
            color: rgb(255, 255, 255);
            background: $linear;
        }

        .button#controlled-tab-example-tab-home {
            color: rgba(59, 51, 53, 0);
        }
    }
}

.tab-responsive {
    row-gap: 40px;
    column-gap: 20px;

    .tab-img {
        width: 300px;
        height: auto;
        border-radius: 8px;
    }
}