header {

    width: 100%;
    z-index: 1;
    padding-top: 10px;
    background-color: transparent;
    z-index: 500;


    &.sticky {
        position: fixed;
        background-color: #0F101E;
    }


    .navbar-brand {
        img {
            width: 70px;
        }
    }

    .navbar-toggler {
        .close {
            display: none;
        }

        .open {
            display: block;
        }

        &[aria-expanded="true"] {
            .close {
                display: block;
            }

            .open {
                display: none;
            }

        }

        &[aria-expanded="false"] {}
    }

    .navbar-nav {
        border-radius: 1000px;
        background: #010314;
        box-shadow: 0px 1px 1px 1px rgba(255, 255, 255, 0.15);
        display: inline-flex;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
        gap: 25px;
        margin: auto;

        .nav-item {
            .nav-link {
                transition: 0.3s all;
                color: #ffffff;
                font-size: 18px;
                letter-spacing: 0.36px;

                &.active,
                &:hover {
                    border-radius: 1000px;
                    background: $primary;
                    color: #000;
                    display: flex;
                    padding: 7px 22px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                }

            }

        }
    }

    .header-action {
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            list-style-type: none;

            li {
                color: #000;

                &:last-child {
                    div {
                        display: none;
                        cursor: pointer;
                        background-color: $primary;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        color: #000;
                    }

                }
            }
        }
    }
}

.brand {

    flex-shrink: 0;
}

@media screen and (max-width : 767px) {

    header {
        position: relative;
        width: 100%;


        .container {
            position: relative;
        }

        .navbar-nav {
            width: 100%;
            border-radius: 10px;

            .nav-item {
                width: 100%;
                text-align: center;
              
            }
        }

        .header-action {
            position: absolute;
            top: 14px;
            right: 15px;

            ul {
                li {
                    color: #000;

                    &:last-child {
                        div {
                            display: flex;

                        }

                    }
                }
            }
        }
    }


}