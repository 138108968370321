.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
}

.overlay {
    background-color: #ecf0f3;
}

.modal-content {
    position: fixed;
    top: 18px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 90%;
    min-width: 90%;
    max-height: 435px;
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;


    .modal-header {
        .modal-title {
            font-size: 15px;
            font-weight: 600;
            color: #007cc3;
        }

    }

    .modal-body {
        margin-top: 10px;
        height: 334px;
        min-height: 334px;
        max-height: 334px;
        overflow-y: scroll;
    }

    .modal-dialog {}
}

.modal-text {
    padding: 0 100px 0 50px;

    h1 {
        margin: 20px 0 20px 0;
        font-size: 30px;
    }

    p {
        font-size: 17px;
        margin-bottom: 20px;
        line-height: 25px;
    }

    button {
        margin-right: 20px;
        color: #ff014f;

        i {
            margin-left: 10px;
        }

        &.close-modal {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 50px;
            height: 50px;
            text-align: center;
            border-radius: 50%;
            padding: 0 0;
            font-size: 20px;

            i {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width : 767px) {

    .modal,
    .overlay {
        overflow: scroll;
    }


    .modal {
        .modal-img {
            margin: 50px 0 50px 0;
        }
    }

    .modal .right,
    .modal .left {
        padding: 0;
    }
}