.form-group {
    margin-bottom: 20px;

    textarea,
    input {
        width: 100%;
        background: #ffffff12;
        border: none;
        border-radius: 30px;
        font-size: 16px;
        color: #fff;
        padding: 15px 20px;
        line-height: 1;
        height: 50px;
        display: block;

        &:focus,
        &:hover,
        &:active {
            color: #ffffff;
            border: 1px solid $primary;
            outline: 0;
            box-shadow: none;
            border-radius: 25px;
        }

        &::placeholder {
            font-size: 16px;
            color: #aaabb2;
            line-height: 1;
        }
    }

    textarea {
        min-height: 200px;
        max-height: 200px;
    }
}