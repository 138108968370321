.hero-section {
   position: relative;

   .djps {
      position: absolute;
      bottom: 237px;
      left: 20px;
      width: 22%;
      z-index: -1;
   }

   .title {
      font-weight: bold;
      font-size: 66px;
      line-height: 77px;


      span {
         color: $primary;
         padding-left: 10px;
      }
   }
}

.Ellipse {
   position: absolute;
   top: 0;
   right: 0;
   width: 55%;
   z-index: -1;
}

@media screen and (max-width : 767px) {
   .hero-section {
      margin-top: 65px;

      .djps {
        display: none;
      }

      .title {
         font-weight: bold;
         font-size: 35px;
         line-height: 45px;
         text-align: center;
      }
   }

   .Ellipse {
      width: 82%;
   }
}