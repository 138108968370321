.blog {
	padding: 10px;

	img {
		width: 100%;
		border-radius: 20px;
		height: 200px;
		object-fit: cover;
	}

	.date {
		color: #fff;
		opacity: .7;
	}

	.content {
		padding: 20px;
	}
}


.document-area {
	position: relative;
	z-index: 2;
}



.document-form-wrap {
	background: #0F101E;
	border: 1px solid rgba(43, 44, 56, 0.46);
	padding: 60px 50px 50px;
	text-align: center;
	border-radius: 15px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;





}

.document-wrap {
	background: #0F101E;
	border: 1px solid rgba(43, 44, 56, 0.46);
	padding: 60px 64px 50px;
	border-radius: 15px;
	text-align: center;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.title {
		margin-bottom: 35px;
		font-size: 28px;
	}

	.list-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 20px;
		margin-bottom: 25px;

		a {
			font-size: 14px;
			font-weight: 600;
			line-height: 1;
			color: #fff;

			&:hover {
				color: $primary;
			}

			.icon {
				width: 130px;
				height: 104px;
				background: #ffffff12;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 35px;
				line-height: 0;
				margin-bottom: 5px;
			}
		}
	}

	.btn {
		margin-top: 20px;
	}
}

.document-shape {
	img {
		position: absolute;
		right: 3%;
		top: -2%;
		z-index: -1;
	}
}

.download-area {
	padding-top: 130px;
	padding-bottom: 130px;
}

.download-inner-wrap {
	background: #0F101E;
	border-radius: 15px;
	border: 1px solid rgba(43, 44, 56, 0.46);
	padding: 75px 80px 0;
	position: relative;
	z-index: 1;
}

.download-content {
	padding: 40px 0 115px;

	.section-title {
		margin-bottom: 40px;

		.title {
			font-size: 32px;
			color: #fff;
		}
	}
}

.download-list {
	.list-wrap {
		display: flex;
		align-items: center;
		gap: 15px 50px;
		flex-wrap: wrap;
		margin-bottom: 50px;

		li {
			display: flex;
			align-items: center;
			font-size: 20px;
			font-weight: 500;
			color: #fff;
			gap: 10px;

			span {
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 0 0 auto;
				background: #1a1c2b;
				border-radius: 50%;
				box-shadow: 0 1px 4px rgba(0, 20, 90, 0.1);
				font-size: 16px;
				color: #fff;
			}
		}
	}
}

.download-btn-wrap {
	display: flex;
	align-items: center;
	gap: 30px;
	flex-wrap: wrap;

	.download-btn {
		img {
			max-height: 60px;
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
			width: auto;
			height: auto;
		}
	}
}

.download-img {
	position: relative;
	z-index: 1;
	text-align: right;

	img {
		height: auto;

		&:nth-child(2) {
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
	}
}

.download-shape-wrap {
	img {
		position: absolute;
		z-index: -1;
		height: auto;

		&:nth-child(1) {
			left: 0;
			bottom: 0;
		}

		&:nth-child(2) {
			right: 0;
			bottom: 0;
		}
	}
}

.document-area {
	position: relative;
	z-index: 2;
}


.features-area {
	padding-top: 140px;
	padding-bottom: 110px;

	.section-title {
		margin-bottom: 70px;

		.title {
			font-size: 32px;
			color: #fff;
		}
	}

	.features-item {
		display: flex;
		align-items: center;
		background: #0F101E;
		border: 1px solid rgba(43, 44, 56, 0.46);
		border-radius: 15px;
		padding: 40px 70px 40px 55px;
		margin-bottom: 30px;

		.features-content {
			width: 50%;
			margin-right: 20px;

			.title {
				font-size: 28px;
				font-weight: 600;
				letter-spacing: -0.84px;
				margin-bottom: 25px;

				a {
					text-decoration: none;
					color: inherit;
					transition: color 0.3s;

					&:hover {
						color: var(--tg-primary-color);
					}
				}
			}

			p {
				margin-bottom: 0;
				color: #fff;
			}
		}

		.features-img {
			flex-grow: 1;
			text-align: right;

			img {
				width: 150px;
				height: auto;
			}
		}
	}

	.features-bg {
		background-size: cover;
		background-position: center;
		border-radius: 20px 20px 0 0;
		position: relative;
		margin: 0 20px;
		overflow: hidden;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg,
					$primary 0%,
					$secondary 47.6%,
					#000 65.23%);
			z-index: -1;
			transform: rotate(180deg);
		}
	}

	.features-inner-wrap {
		position: relative;
		padding: 140px 0;
		z-index: 1;
	}

	.features-line-shape {
		width: 100%;
		height: 2px;
		background: linear-gradient(225deg,
				#010314,
				#ffffff73 54.98%,
				#010314fc);
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.features-item-wrap {
		margin-bottom: 90px;
	}

	.features-item-two {
		border-radius: 15px;
		background: linear-gradient(180deg, #ffffff24, #fff0);
		box-shadow: 0 16px 18px rgba(1, 12, 50, 0.1);
		display: flex;
		align-items: center;
		padding: 30px;
		margin-bottom: 30px;
		transition: transform 0.3s linear;

		&:hover {
			transform: translateY(-5px);
		}

		.features-img-two {
			width: 95px;
			flex: 0 0 auto;
			margin-right: 30px;

			img {
				height: auto;
			}
		}

		.features-content-two {
			.title {
				margin-bottom: 0;
				font-size: 24px;
				font-weight: 700;
				letter-spacing: -0.72px;
				color: #fff;
			}
		}
	}
}

.faq-inner-wrap {
	padding: 140px 0;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background: linear-gradient(225deg, #010314, #ffffff73 54.98%, #010314fc);
	}
}

.faq-wrap {
	margin: 0 30px;

	.accordion-item {
		background-color: #0F101E;
		border: 1px solid rgba(43, 44, 56, 0.46);
		border-radius: 15px;
		overflow: hidden;
		margin-bottom: 20px;

		&.active {
			background: linear-gradient(0deg, $secondary 0%, #0F101E 47.6%, #000 98.23%);
			border: none;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:last-of-type .accordion-button.collapsed {
			border-bottom-right-radius: 15px;
			border-bottom-left-radius: 15px;
		}

		&:first-of-type .accordion-button {
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
		}

		.accordion-button {
			padding: 38px 90px 38px 55px;
			font-size: 24px;
			color: #fff;
			background-color: transparent;
			font-weight: 600;
			letter-spacing: -0.48px;
			position: relative;

			&:after {
				flex-shrink: 0;
				content: "↑";
				background-image: none;
				font-size: 30px;
				font-weight: 600;
				position: absolute;
				right: 45px;
				top: 38px;
				color: #fff;
				opacity: 0.3;
			}

			&:not(.collapsed):after {
				background-image: none;
				opacity: 1;
			}

			&:not(.collapsed) {
				color: #fff;
				background-color: transparent;
				box-shadow: none;
			}

			&:focus {
				border-color: none;
				box-shadow: none;
			}
		}

		.accordion-body {
			padding: 0 55px 70px;

			p {
				font-size: 18px;
				margin-bottom: 0;
				color: #cbd0d4;
			}
		}
	}
}


.rating-card {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.starts {


		span {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			color: $primary;
			font-size: 30px;
		}
	}

	.section-detail {
		text-align: center;
		max-width: 600px;
		color: #fff;
	}

	.profile {

		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;

		.user-detail {
			text-align: center;
			margin-top: 10px;

			.user_name {
				color: #fff;
				font-weight: 600;
				font-size: 1.4rem;
			}

			.job {
				color: #fff;
				font-weight: 500;
			}
		}

		.user {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			object-fit: cover;
			margin-top: 30px;

			img {
				border-radius: 50%;
				width: 80px;
				height: 80px;

			}
		}

	}
}

.partnerslider {

	.partner {
		background-color: rgba(0, 0, 0, .1);
		width: 240px !important;
		margin: auto !important;

		&:hover {
			img {
				filter: grayscale(0);
				opacity: 1;
			}
		}

		img {
			width: 95%;
			filter: grayscale(100%);
			opacity: .8;
			transition: all .5s ease;


		}
	}
}

.why-page {
	h1 {
		color: #fff;
		font-size: 20px;
	}
}

.why-card {
	padding: 40px;
	border-radius: 15px;
	min-height: 350px;
	border: 2px solid #fff;
	position: relative;
	background: #0F101E;
	border: 1px solid rgba(43, 44, 56, 0.46);

	&:hover {
		border: 2px solid $primary;
		transition-duration: 1s;
	}

	h3 {
		font-weight: bold;
		color: $primary;
	}

	p {
		font-size: 16px;
		color: #fff;
	}

	.why-img {
		height: 100px;
		position: absolute;
		bottom: 20px;
		right: 50px;
	}
}

@media screen and (max-width : 767px) {
	.banner-bg {
		padding: 100px 0 50px;
		min-height: 100%;

		img {
			width: 50%;
		}
	}

	.banner-content {
		position: relative;
		z-index: 1;

		.title {
			font-size: 30px;
			margin-bottom: 25px;
		}

		p {
			font-size: 18px;
			margin-bottom: 50px;
			line-height: 20px;
		}
	}

	.banner-shape-wrap {
		display: none;
	}

	.download-inner-wrap {
		padding: 20px 20px 0;
	}

	.document-form-wrap {
		padding: 20px;

	}
}