// .pagination {
//     --bs-pagination-bg: #ffffff;
//     --bs-pagination-color: #000000;
//     --bs-pagination-border-width: 1px;
//     --bs-pagination-border-color: transparant;
//     --bs-pagination-hover-color: var(--bs-link-hover-color);
//     --bs-pagination-hover-bg: #e9ecef;
//     --bs-pagination-hover-border-color: transparant;
//     --bs-pagination-focus-color: white;
//     --bs-pagination-focus-bg: #007efd;
//     --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
//     --bs-pagination-active-color: #000000;
//     --bs-pagination-active-bg: rgb(255, 255, 255);
//     --bs-pagination-active-border-color: #0d6efd;
//     --bs-pagination-disabled-color: #fff;
//     --bs-pagination-disabled-bg: #0095DF;
//     --bs-pagination-disabled-border-color: #007fff;

//     .next {
//         padding: 6px 24px;
//     }
// }


@keyframes cdp-in {
    from {
        transform: scale(1.5);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

.cdp {
    position: relative;
    text-align: center;
    padding: 20px 0;
    font-size: 0;
    margin: 30px 10px 10px 10px;

    animation: cdp-in 500ms ease both;
    animation-timeout: 200ms;

    &_i {
        font-size: 14px;
        text-decoration: none;
        transition: background 250ms;
        display: inline-block;
        text-transform: uppercase;
        margin: 0 3px 6px;
        height: 38px;
        min-width: 38px;
        border-radius: 38px;
        border: 2px solid #40b9ff;
        line-height: 38px;
        padding: 0;
        color: $primary;
        font-weight: 700;
        letter-spacing: 0.03em;
        display: none;

        &:first-child,
        &:last-child {
            padding: 0 16px;
            margin: 0 12px 6px;
        }

        &:last-child,
        &:nth-child(2),
        &:nth-last-child(2) {
            display: inline-block;
        }
    }

    &_i:hover {
        background-color: #40b9ff;
        color: #fff;
    }

    &:not([actpage="1"]) &_i:nth-child(1) {
        display: inline-block;
    }
}

@for $i from 1 through 80 {
    .cdp[actpage="#{$i}"] {

        // 3 before
        .cdp_i:nth-child(#{$i - 2}):not(:first-child):not(:nth-child(2)) {
            display: inline-block;
            pointer-events: none;
            color: transparent;
            border-color: transparent;
            width: 50px;

            &:after {
                content: '...';
                color: #40b9ff;
                font-size: 32px;
                margin-left: -6px;
            }
        }

        // 2 before
        .cdp_i:nth-child(#{$i - 1}):not(:first-child) {
            display: inline-block;
        }

        // before
        .cdp_i:nth-child(#{$i}):not(:first-child) {
            display: inline-block;
        }

        // active
        .cdp_i:nth-child(#{$i + 1}) {
            background-color: #40b9ff;
            color: #fff;
            display: inline-block;

            +.cdp_i:last-child {
                display: none !important;
            }
        }

        // next
        .cdp_i:nth-child(#{$i + 2}):not(:last-child) {
            display: inline-block;
        }

        // 2 next
        .cdp_i:nth-child(#{$i + 3}):not(:last-child) {
            display: inline-block;
        }

        // 3 next
        .cdp_i:nth-child(#{$i + 4}):not(:last-child):not(:nth-last-child(2)) {
            display: inline-block;
            pointer-events: none;
            color: transparent;
            border-color: transparent;
            width: 50px;

            &:after {
                content: '...';
                color: #40b9ff;
                font-size: 32px;
                margin-left: -6px;
            }
        }
    }
}