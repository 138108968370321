.table {

    &.table-theme {
        thead {
          background-color: rgb(41 172 246 / 47%);
        }

        tbody {
            tr {
                td {
                    font-size: .9rem;
                    vertical-align: top;
                }
            }
        }
    }

    thead {

        tr {
            th {
                font-size: .9rem;
            }
        }
    }

    tbody {
    }
}

.table-actions {
    a {
        font-size: 1.3rem;
    }
}


.table > :not(:first-child) {
    border-top: 0;
}

.va-middle {
    td, th {
        vertical-align: middle;
    }
}

.table-add-sale {
    .col-input {
        max-width: 70px;
    }
}

.dropdown-table-actions {
    > a {
        font-size: 1.3rem;
    }

    .dropdown-menu {
        a {
            color:black;
            font-size: .9rem;
            display: flex;
            align-items: center;

            &:hover {
               

                i {
                    color: $secondary;
                }
            }

            i {
                margin-right: 0.5rem;
            }
        }
    }
}

.table-condensed {
    thead {
        tr {
            th {
                font-size: .8rem;
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: .8rem;
            }
        }
    }
}

.table-sticky {
    thead {


        th {
            position: sticky;
            top: -1rem;
            background-color: #D9F0F9;
            z-index: 1;
        }
    }
}

