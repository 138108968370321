.progress,
.progress-stacked {
    --bs-progress-height: 8px;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: var(--bs-secondary-bg);
    --bs-progress-border-radius: var(--bs-border-radius);
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);

    .bg-success {
        width: 40%;
        --bs-bg-opacity: 1;
        background-color: $primary !important;
    }

    .progress-bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        color: var(--bs-progress-bar-color);
        text-align: center;
        white-space: nowrap;
        background-color: $primary;
        transition: var(--bs-progress-bar-transition);
    }
}

.slick {
    background: #000;
    width: 100%;
    height: 100%;
    padding: 30px;

    .section-title {
        color: #fff !important;
    }
}

.our-progress-bar {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    margin-top: 100px;


    li {
        width: 100%;
    }

    label {
        color: #fff;
        margin-bottom: 20px;
        font-size: 1.3rem;
    }
}