.i.ri-menu-3-fill {
    color: $primary;
}

.site-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eae5e5;

    .logo {
        width: 70%;
    }

    .link {
        display: flex;
    }

}

.socila-link {
    display: flex;
    flex-direction: row;
    align-content: flex-start;

    align-items: flex-start;
    padding: 0 1.2rem;

    .icon {
        margin-right: 10px;
    }

    .detail {
        max-width: calc(100% - 21px);

        h5 {
            margin: 0;
            font-weight: 500;
            font-size: 20px;
        }

        p {
            font-weight: 500;
            font-size: 17px;
            color: $gray;
            margin: 0;
        }
    }
}



@media screen and (max-width : 1209px) {
    .site-header {
        display: none;
    }

}


.site-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eae5e5;

    .logo {
        width: 70%;
    }

    .link {
        display: flex;
    }

}

.top-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 1.2rem;
    justify-content: space-between;
    border-bottom: 1px solid rgb(0 0 0 / 13%);

    .link {
        display: flex;

        .socila-link {
            align-items: center;
            border-right: 1px solid rgb(0 0 0 / 13%);

            &:last-child {
                border-right: 1px solid rgb(0 0 0 / 0%)
            }

        }

    }

    .icon {
        margin-right: 10px;

        img {
            width: 30px;
        }
    }

    .detail {
        max-width: calc(100% - 21px);

        h5 {
            margin: 0;
            font-weight: 400;
            font-size: 17px;
            color: rgba(0, 0, 0, 0.5);
        }

        p {
            font-weight: 600;
            font-size: 16px;
            color: black;
            margin: 0;
        }
    }
}