.blog-item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  img {
    border-radius: 10px;
    width: 100%;
    transition: all .5s ease;
  }

  &:hover {
    img {
      transform: scale(1.03);
    }
  }

  span {
    position: absolute;
    left: 44px;
    text-align: center;
    background-color: $primary;
    display: none;
    color: #FFF;
    padding: 10px 18px;
    border-radius: 0 0 22px 22px;
    font-weight: bold;
  }

  .content {
    padding: 32px;
    min-height: 289px;

    h6 {
      color: $primary;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
    }

    p {
      color: rgba(104, 119, 153, 1);
      margin-top: 11px;
      font-size: 14px;
      font-weight: 500;
      min-height: 120px
    }

    a {
      color: rgba(104, 119, 153, 1);
      font-size: 12px;
      font-weight: bold;

      &:hover {
        color: $primary;
      }
    }
  }
}


.media-content-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

 li {
  position: relative;
  padding-left: 32px;
  > i {
    position: absolute;
    left: 0;
    top: -3px;
    color: $primary;
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }
 }
}