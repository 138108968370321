.banner {
	background-size: cover;
	background-position: center;
	border-radius: 0 0 20px 20px;
	position: relative;
	padding: 140px 0 120px;
	min-height: 100vh;
	display: flex;
	align-items: center;
	margin: 0 20px;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, $secondary 0%, #c79707 47.6%, transparent 65.23%);
		z-index: -1;
		border-radius: 0 0 20px 20px;
	}

}

.banner-content {
	position: relative;
	z-index: 1;

	.title {
		font-size: 58px;
		margin-bottom: 45px;
		font-weight: 700;
		color: #fff;

		span {
			background: linear-gradient(180deg, #fff 0%, $secondary 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	p {
		font-size: 20px;
		margin-bottom: 55px;
		color: #fff;
		font-weight: 500;
	}
}

.banner-scroll-down {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	bottom: 15px;

	a {
		color: #fff;
		display: block;
		width: 30px;
		height: 50px;
		text-align: center;

		span {
			display: block;
			width: 15px;
			height: 15px;
			border-bottom: 2px solid #fff;
			border-right: 2px solid #fff;
			transform: rotate(45deg);
			margin: -10px 0 0 8px;
			animation: animate 2s infinite;


			&:nth-child(2) {
				animation-delay: -.2s
			}

			&:nth-child(3) {
				animation-delay: -.4s
			}

		}

		@keyframes animate {
			0% {
				opacity: 0;
				transform: rotate(45deg) translate(-15px, -15px)
			}

			50% {
				opacity: 1
			}

			to {
				opacity: 0;
				transform: rotate(45deg) translate(15px, 15px)
			}
		}
	}
}


.banner-shape-wrap img {
	position: absolute
}

.banner-shape-wrap img:nth-child(1) {
	left: 107px;
	bottom: 134px
}

.banner-shape-wrap img:nth-child(2) {
	right: 107px;
	bottom: 120px
}





.banner-shape-wrap img {
	position: absolute
}

.banner-shape-wrap img:nth-child(1) {
	left: 107px;
	bottom: 134px
}

.banner-shape-wrap img:nth-child(2) {
	right: 107px;
	bottom: 120px
}

@keyframes animate {
	0% {
		opacity: 0;
		transform: rotate(45deg) translate(-15px, -15px)
	}

	50% {
		opacity: 1
	}

	to {
		opacity: 0;
		transform: rotate(45deg) translate(15px, 15px)
	}
}

@keyframes alltuchtopdown {
	0% {
		transform: rotateX(0) translateY(0)
	}

	50% {
		transform: rotateX(0) translateY(-30px)
	}

	to {
		transform: rotateX(0) translateY(0)
	}
}

.alltuchtopdown {
	-webkit-animation-name: alltuchtopdown;
	animation-name: alltuchtopdown;
	-webkit-animation-duration: 6s;
	animation-duration: 6s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear
}

@keyframes leftToRight {
	0% {
		transform: rotateX(0) translate(0)
	}

	50% {
		transform: rotateX(0) translate(50px)
	}

	to {
		transform: rotateX(0) translate(0)
	}
}

.leftToRight {
	-webkit-animation-name: leftToRight;
	animation-name: leftToRight;
	-webkit-animation-duration: 8s;
	animation-duration: 8s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear
}

