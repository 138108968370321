.button {
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	text-align: center;
	transition: all 0.4s ease-in-out;
	border: 1px solid transparent;
	font-size: 17px;
	font-weight: 500;
	padding: 8px 17px;
	border-radius: 50px;
	gap: 10px;
	text-wrap: nowrap;

	outline: none;
	cursor: pointer;

	span {
		font-size: 20px;
	}

	&.primary {
		background: $primary;
		color: #000000;

		&:hover,
		&:focus,
		&:active {
			opacity: .7;
		}
	}

	&.primary-outline {
		background: transparent;
		border: 1px solid $primary;
		color: $primary;

		&:hover,
		&:focus,
		&:active {
			background-color: $primary;
			color: #fff;
		}
	}

	&.white-outline {
		background: transparent;
		border: 1px solid #fff;
		color: #fff;

		&:hover,
		&:focus,
		&:active {
			background-color: #fff;
			color: #000;
		}
	}

	&.secondary {
		background: $primary;
		color: #fff;
	}

	&.long {
		width: 100%;
	}
}

.button-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;

	&.center {
		justify-content: center;
	}
}

@media screen and (max-width : 767px) {
	.button {
		padding: 8px 20px;
		font-size: 16px;
	}
}