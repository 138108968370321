.about-info {
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .info {
        padding: 60px !important;
        background: #f6f6f6;

        h1 {
            position: relative;
            font-size: 28px;
            display: block;
            margin-top: 0;
            margin-bottom: 30px;
            line-height: 1.2em;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 20px;
                height: 2px;
                background-color: $primary;
            }
        }
    }



    p {}
}