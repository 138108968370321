.bog-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 124, 195, 0.25);
    border-radius: 30px;
    width: 274px;
    height: 348px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin: auto;
    padding: 10px;


    &::after {
        content: "";
        width: 0;
        height: 8px;
        border-radius: 0.2rem;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $linear;
        transition: width 200ms ease-in;
    }

    &:hover::after,
    &:focus::after {
        width: 100%;
    }

    .model {
        position: relative;
        margin-bottom: 27px;
        margin-top: 17px;

        .profile {
            width: 155px;
            height: 155px;
            border: 1px solid $primary;
            border-radius: 100px;
        }

        .crical {
            display: none;
            position: absolute;
            left: 8px;
            top: 8px;
            margin: auto;
            // width: 140px;
            // height: 140px;
            // border: 1px solid $primary;
            // border-radius: 50%;
        }
    }


    h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $primary;
    }

    p {
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);

    }

    .social {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0;

        li {
            list-style: none;
            padding: 0px .7rem;

            a {
                color: $primary;

                i {
                    font-size: 24px;
                }
            }
        }
    }
}

// .bog {



//     .content-filter {
//         overflow: hidden;
//         width: 96%;
//         backface-visibility: hidden;
//         -webkit-backface-visibility: hidden;

//     }

//     .filter {
//         position: relative;
//     }

//     .filter-btns {
//         padding: 0.4rem;
//         margin-bottom: 1em;
//         background-color: #fff;
//         border-bottom: 1px solid #e5e5e5;
//     }

//     .filter-btn {
//         display: inline-block;
//         padding: 0.5em 1em;
//         color: #212426;
//         font-size: 1.1rem;
//         border-radius: 0px;
//         text-transform: lowercase;
//         background-color: #fff;
//         transition: all 300ms linear;
//     }

//     .filter-btn--reset {
//         border-right: 1px solid #e5e5e5;
//         border-radius: 0;
//     }


//     .filter~.filter-item {
//         background-color: #fff;
//         border: 10px solid #fff;
//         float: left;
//         -webkit-transform: scale(1);
//         transform: scale(1);
//         width: 100%;
//         height: 100%;
//         margin: 10px;
//         padding: 20px;
//         opacity: 1;
//         visibility: visible;
//         will-change: transform, visibility;
//         transition: all 600ms ease;
//         /* 	display:block; */
//     }

//     @media only screen and (min-width: 480px) and (max-width:600px) {

//         .filter~.filter-item {
//             --item-width: 96vw;
//             width: var(--item-width);
//             height: var(--item-width);
//         }
//     }


//     @media only screen and (min-width: 601px) and (max-width:960px) {

//         .filter~.filter-item {
//             --item-width: 48vw;
//             width: var(--item-width);
//             height: var(--item-width);

//         }

//     }

//     @media only screen and (min-width: 960px) {

//         .filter~.filter-item {

//             --item-width: 24vw;
//             width: var(--item-width);
//             height: var(--item-width);


//         }

//     }



//     .filter:target~.filter-item {

//         -webkit-transform: scale(0);
//         transform: scale(0);
//         width: 0;
//         height: 0;
//         margin: 0;
//         padding: 0;
//         opacity: 0;
//         visibility: hidden;
//         transition: all 600ms ease;
//         /* 	display:none; */

//     }

//     [id="tag:Administration"]:target~[data-tag~="Administration"],
//     [id="tag:Fee-concession"]:target~[data-tag~="Fee concession"] {
//         -webkit-transform: scale(1);
//         transform: scale(1);
//         width: var(--item-width);
//         height: var(--item-width);
//         margin: 10px;
//         padding: 20px;
//         opacity: 1;
//         visibility: visible;
//         transition: all 600ms ease;
//         /* 	display:block; */
//     }

//     [id="tag:Administration"]:target~.filter-btns [href="#tag:Administration"],
//     [id="tag:Fee-concession"]:target~.filter-btns [href="#tag:Fee concession"] {
//         background-color: #212426;
//         color: #fff;
//         pointer-events: none;
//     }

    

//     [data-tag~="Administration"] {
//         background: url("https://unsplash.it/480/640?random=photo4");
//         background-size: cover;
//     }

//     [data-tag~="Fee-concession"] {
//         background: url("https://unsplash.it/480/640?random=photo3");
//         background-size: cover;
//     }

   
    


// }