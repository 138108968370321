.pump-logos {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
        img {
            max-width: 170px;
            display: inline-block;
            margin: 10px;
        }
    }
}

.reports {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
        background-color: #FFF;
        border-radius: 0.4rem;
        padding: 2rem;
        text-align: center;
        flex-basis: 17%;
        margin: 1%;
        justify-content: center;
        box-shadow: 0px 11px 32px rgb(0 128 249 / 8%);

        p {
            font-weight: bolder;
            font-size: 1.4rem;
            max-width: 110px;
            margin: 0 auto 1.5rem auto;
            line-height: 1.8rem;
        }

        .button-sm {
            padding: 0.5rem;
            font-size: 0.8rem;
        }
    }
}

.features_2 {
    padding: 3rem 0;

    .feature_box_2 {
        background-color: #FFF;
        box-shadow: 0px 11px 32px rgb(0 128 249 / 8%);
        border-radius: 25px;
        padding: 2rem;
        text-align: center;

        .icon {
            margin-bottom: 1rem;

            span {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: auto;
                background-color: rgba(242, 141, 32, 0.2);
                color: #F28D20;
                font-size: 2rem;
            }
        }

        h3 {
            font-size: 1.2rem;
        }

        p {
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.9rem;
        }
    }
}

.cta {
    background-color: #061956;
    padding: 5rem 0;

    .cta-content {
        text-align: center;
        color: #FFF;

        h3 {
            font-size: 2.5rem;
            margin-bottom: 1.4rem;
        }

        p {
            font-size: 1.2rem;
            font-weight: 300;
        }

        .cta-buttons {
            margin-top: 2rem;

            .button {
                margin: 0.5rem;
            }
        }
    }
}