@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
body {
  background-color: white;
  font-family: "Montserrat", sans-serif;
}

ul {
  padding-left: 0;
}

a {
  text-decoration: none;
}

.simple-section {
  padding: 70px;
}

.title {
  font-size: 28px;
  margin-bottom: 35px;
  color: #fff;
}

.section-title .title {
  background: linear-gradient(180deg, #fff 0%, #45F882 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  margin-bottom: 0;
  font-weight: 600;
}
.section-title span {
  color: #FFBE18;
}

.section-detail {
  color: #fff;
}

.text-primary {
  color: #FFBE18 !important;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(0%);
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  pointer-events: none;
  animation: slide-in-left 1s forwards;
}

@keyframes slide-in-left {
  1% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media screen and (max-width: 767px) {
  #body {
    width: 100%;
    overflow-x: hidden;
  }
  .section-title {
    font-size: 20px;
    font-weight: bold;
  }
  .section-title span {
    padding-left: 0px;
  }
  .section-title .title {
    font-size: 35px;
  }
  .simple-section {
    padding: 10px;
    width: 100%;
    overflow: hidden;
  }
  .section-detail {
    text-align: center;
    padding-top: 20px;
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
}
body {
  font-family: "Montserrat", sans-serif;
  top: 0 !important;
  background-color: rgb(0, 0, 0);
}

a {
  color: #FFBE18;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  color: #45F882;
}

.i.ri-menu-3-fill {
  color: #FFBE18;
}

.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eae5e5;
}
.site-header .logo {
  width: 70%;
}
.site-header .link {
  display: flex;
}

.socila-link {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  padding: 0 1.2rem;
}
.socila-link .icon {
  margin-right: 10px;
}
.socila-link .detail {
  max-width: calc(100% - 21px);
}
.socila-link .detail h5 {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
}
.socila-link .detail p {
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}

@media screen and (max-width: 1209px) {
  .site-header {
    display: none;
  }
}
.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eae5e5;
}
.site-header .logo {
  width: 70%;
}
.site-header .link {
  display: flex;
}

.top-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 1.2rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}
.top-link .link {
  display: flex;
}
.top-link .link .socila-link {
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.13);
}
.top-link .link .socila-link:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0);
}
.top-link .icon {
  margin-right: 10px;
}
.top-link .icon img {
  width: 30px;
}
.top-link .detail {
  max-width: calc(100% - 21px);
}
.top-link .detail h5 {
  margin: 0;
  font-weight: 400;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.5);
}
.top-link .detail p {
  font-weight: 600;
  font-size: 16px;
  color: black;
  margin: 0;
}

header {
  width: 100%;
  z-index: 1;
  padding-top: 10px;
  background-color: transparent;
  z-index: 500;
}
header.sticky {
  position: fixed;
  background-color: #0F101E;
}
header .navbar-brand img {
  width: 70px;
}
header .navbar-toggler .close {
  display: none;
}
header .navbar-toggler .open {
  display: block;
}
header .navbar-toggler[aria-expanded=true] .close {
  display: block;
}
header .navbar-toggler[aria-expanded=true] .open {
  display: none;
}
header .navbar-nav {
  border-radius: 1000px;
  background: #010314;
  box-shadow: 0px 1px 1px 1px rgba(255, 255, 255, 0.15);
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: auto;
}
header .navbar-nav .nav-item .nav-link {
  transition: 0.3s all;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.36px;
}
header .navbar-nav .nav-item .nav-link.active, header .navbar-nav .nav-item .nav-link:hover {
  border-radius: 1000px;
  background: #FFBE18;
  color: #000;
  display: flex;
  padding: 7px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
header .header-action ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  list-style-type: none;
}
header .header-action ul li {
  color: #000;
}
header .header-action ul li:last-child div {
  display: none;
  cursor: pointer;
  background-color: #FFBE18;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #000;
}

.brand {
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  header {
    position: relative;
    width: 100%;
  }
  header .container {
    position: relative;
  }
  header .navbar-nav {
    width: 100%;
    border-radius: 10px;
  }
  header .navbar-nav .nav-item {
    width: 100%;
    text-align: center;
  }
  header .header-action {
    position: absolute;
    top: 14px;
    right: 15px;
  }
  header .header-action ul li {
    color: #000;
  }
  header .header-action ul li:last-child div {
    display: flex;
  }
}
.footer-bg {
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
  position: relative;
  padding: 150px 0 0;
  margin: -200px 20px 0;
  overflow: hidden;
}
.footer-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #20212e;
  z-index: -2;
  transform: rotate(180deg);
}

.footer-top {
  padding: 110px 0 30px;
  z-index: 2;
  position: relative;
}

.footer-widget {
  margin-bottom: 40px;
}
.footer-widget .fw-title {
  margin-bottom: 15px;
  font-size: 18px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
}

.footer-link .list-wrap {
  list-style-type: none;
}
.footer-link .list-wrap li {
  margin-bottom: 15px;
}
.footer-link .list-wrap li:last-child {
  margin-bottom: 0;
}
.footer-link .list-wrap li a {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  position: relative;
}
.footer-link .list-wrap li a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  transform-origin: right top;
  transform: scaleX(0);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-link .list-wrap li a:hover:before {
  transform-origin: left top;
  transform: scale(1);
}

.footer-newsletter p {
  margin-bottom: 20px;
  font-size: 15px;
  color: #cbd0d4;
  font-weight: 500;
}
.footer-newsletter .form-group {
  position: relative;
  margin-bottom: 0;
}
.footer-newsletter .form-group input {
  border: 1px solid #000;
  background: #0F101E;
}
.footer-newsletter .form-group button {
  line-height: 1;
  border: none;
  padding: 0;
  position: absolute;
  right: 13px;
  top: 25%;
  font-size: 30px;
  color: #FFBE18;
  background: transparent;
}

.footer-bottom {
  padding: 30px 0;
  position: relative;
}
.footer-bottom:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(225deg, #fff 0%, rgba(255, 255, 255, 0.45) 54.98%, rgb(255, 190, 24) 100%);
}

.copyright-text p {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.hero-section {
  position: relative;
}
.hero-section .djps {
  position: absolute;
  bottom: 237px;
  left: 20px;
  width: 22%;
  z-index: -1;
}
.hero-section .title {
  font-weight: bold;
  font-size: 66px;
  line-height: 77px;
}
.hero-section .title span {
  color: #FFBE18;
  padding-left: 10px;
}

.Ellipse {
  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .hero-section {
    margin-top: 65px;
  }
  .hero-section .djps {
    display: none;
  }
  .hero-section .title {
    font-weight: bold;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
  }
  .Ellipse {
    width: 82%;
  }
}
.about-info {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.about-info .info {
  padding: 60px !important;
  background: #f6f6f6;
}
.about-info .info h1 {
  position: relative;
  font-size: 28px;
  display: block;
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 1.2em;
}
.about-info .info h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 2px;
  background-color: #FFBE18;
}
.button {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  font-size: 17px;
  font-weight: 500;
  padding: 8px 17px;
  border-radius: 50px;
  gap: 10px;
  text-wrap: nowrap;
  outline: none;
  cursor: pointer;
}
.button span {
  font-size: 20px;
}
.button.primary {
  background: #FFBE18;
  color: #000000;
}
.button.primary:hover, .button.primary:focus, .button.primary:active {
  opacity: 0.7;
}
.button.primary-outline {
  background: transparent;
  border: 1px solid #FFBE18;
  color: #FFBE18;
}
.button.primary-outline:hover, .button.primary-outline:focus, .button.primary-outline:active {
  background-color: #FFBE18;
  color: #fff;
}
.button.white-outline {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.button.white-outline:hover, .button.white-outline:focus, .button.white-outline:active {
  background-color: #fff;
  color: #000;
}
.button.secondary {
  background: #FFBE18;
  color: #fff;
}
.button.long {
  width: 100%;
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.button-row.center {
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .button {
    padding: 8px 20px;
    font-size: 16px;
  }
}
.page-head {
  min-height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.page-head h3 {
  font-size: 3rem;
  font-weight: bold;
  color: #FFF;
}
.page-head .breadcrumb li {
  color: rgba(255, 255, 255, 0.6);
}
.page-head .breadcrumb li a {
  color: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 767px) {
  .page-head h3 {
    font-size: 2rem;
  }
}
@keyframes cdp-in {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.cdp {
  position: relative;
  text-align: center;
  padding: 20px 0;
  font-size: 0;
  margin: 30px 10px 10px 10px;
  animation: cdp-in 500ms ease both;
  animation-timeout: 200ms;
}
.cdp_i {
  font-size: 14px;
  text-decoration: none;
  transition: background 250ms;
  display: inline-block;
  text-transform: uppercase;
  margin: 0 3px 6px;
  height: 38px;
  min-width: 38px;
  border-radius: 38px;
  border: 2px solid #40b9ff;
  line-height: 38px;
  padding: 0;
  color: #FFBE18;
  font-weight: 700;
  letter-spacing: 0.03em;
  display: none;
}
.cdp_i:first-child, .cdp_i:last-child {
  padding: 0 16px;
  margin: 0 12px 6px;
}
.cdp_i:last-child, .cdp_i:nth-child(2), .cdp_i:nth-last-child(2) {
  display: inline-block;
}
.cdp_i:hover {
  background-color: #40b9ff;
  color: #fff;
}
.cdp:not([actpage="1"]) .cdp_i:nth-child(1) {
  display: inline-block;
}

.cdp[actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="1"] .cdp_i:nth-child(0):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(1):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(2) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(2) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="1"] .cdp_i:nth-child(3):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(4):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="2"] .cdp_i:nth-child(1):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(2):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(3) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(3) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="2"] .cdp_i:nth-child(4):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(5):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="3"] .cdp_i:nth-child(2):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(3):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(4) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(4) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="3"] .cdp_i:nth-child(5):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(6):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="4"] .cdp_i:nth-child(3):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(4):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(5) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(5) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="4"] .cdp_i:nth-child(6):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(7):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="5"] .cdp_i:nth-child(4):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(5):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(6) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(6) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="5"] .cdp_i:nth-child(7):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(8):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="6"] .cdp_i:nth-child(5):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(6):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(7) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(7) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="6"] .cdp_i:nth-child(8):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(9):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="7"] .cdp_i:nth-child(6):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(7):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(8) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(8) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="7"] .cdp_i:nth-child(9):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(10):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="8"] .cdp_i:nth-child(7):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(8):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(9) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(9) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="8"] .cdp_i:nth-child(10):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(11):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="9"] .cdp_i:nth-child(8):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(9):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(10) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(10) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="9"] .cdp_i:nth-child(11):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(12):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="10"] .cdp_i:nth-child(9):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(10):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(11) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(11) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="10"] .cdp_i:nth-child(12):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(13):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="11"] .cdp_i:nth-child(10):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(11):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(12) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(12) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="11"] .cdp_i:nth-child(13):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(14):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="12"] .cdp_i:nth-child(11):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(12):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(13) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(13) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="12"] .cdp_i:nth-child(14):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(15):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="13"] .cdp_i:nth-child(12):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(13):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(14) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(14) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="13"] .cdp_i:nth-child(15):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(16):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="14"] .cdp_i:nth-child(13):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(14):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(15) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(15) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="14"] .cdp_i:nth-child(16):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(17):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="15"] .cdp_i:nth-child(14):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(15):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(16) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(16) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="15"] .cdp_i:nth-child(17):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(18):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="16"] .cdp_i:nth-child(15):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(16):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(17) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(17) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="16"] .cdp_i:nth-child(18):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(19):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="17"] .cdp_i:nth-child(16):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(17):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(18) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(18) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="17"] .cdp_i:nth-child(19):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(20):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="18"] .cdp_i:nth-child(17):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(18):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(19) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(19) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="18"] .cdp_i:nth-child(20):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(21):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="19"] .cdp_i:nth-child(18):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(19):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(20) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(20) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="19"] .cdp_i:nth-child(21):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(22):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="20"] .cdp_i:nth-child(19):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(20):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(21) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(21) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="20"] .cdp_i:nth-child(22):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(23):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="21"] .cdp_i:nth-child(20):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(21):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(22) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(22) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="21"] .cdp_i:nth-child(23):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(24):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="22"] .cdp_i:nth-child(21):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(22):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(23) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(23) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="22"] .cdp_i:nth-child(24):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(25):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="23"] .cdp_i:nth-child(22):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(23):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(24) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(24) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="23"] .cdp_i:nth-child(25):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(26):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="24"] .cdp_i:nth-child(23):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(24):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(25) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(25) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="24"] .cdp_i:nth-child(26):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(27):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="25"] .cdp_i:nth-child(24):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(25):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(26) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(26) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="25"] .cdp_i:nth-child(27):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(28):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="26"] .cdp_i:nth-child(25):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(26):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(27) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(27) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="26"] .cdp_i:nth-child(28):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(29):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="27"] .cdp_i:nth-child(26):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(27):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(28) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(28) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="27"] .cdp_i:nth-child(29):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(30):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="28"] .cdp_i:nth-child(27):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(28):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(29) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(29) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="28"] .cdp_i:nth-child(30):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(31):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="29"] .cdp_i:nth-child(28):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(29):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(30) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(30) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="29"] .cdp_i:nth-child(31):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(32):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="30"] .cdp_i:nth-child(29):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(30):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(31) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(31) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="30"] .cdp_i:nth-child(32):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(33):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="31"] .cdp_i:nth-child(30):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(31):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(32) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(32) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="31"] .cdp_i:nth-child(33):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(34):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="32"] .cdp_i:nth-child(31):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(32):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(33) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(33) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="32"] .cdp_i:nth-child(34):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(35):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="33"] .cdp_i:nth-child(32):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(33):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(34) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(34) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="33"] .cdp_i:nth-child(35):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(36):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="34"] .cdp_i:nth-child(33):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(34):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(35) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(35) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="34"] .cdp_i:nth-child(36):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(37):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="35"] .cdp_i:nth-child(34):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(35):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(36) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(36) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="35"] .cdp_i:nth-child(37):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(38):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="36"] .cdp_i:nth-child(35):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(36):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(37) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(37) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="36"] .cdp_i:nth-child(38):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(39):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="37"] .cdp_i:nth-child(36):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(37):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(38) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(38) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="37"] .cdp_i:nth-child(39):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(40):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="38"] .cdp_i:nth-child(37):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(38):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(39) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(39) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="38"] .cdp_i:nth-child(40):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(41):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="39"] .cdp_i:nth-child(38):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(39):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(40) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(40) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="39"] .cdp_i:nth-child(41):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(42):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="40"] .cdp_i:nth-child(39):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(40):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(41) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(41) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="40"] .cdp_i:nth-child(42):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(43):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="41"] .cdp_i:nth-child(40):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(41):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(42) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(42) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="41"] .cdp_i:nth-child(43):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(44):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="42"] .cdp_i:nth-child(41):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(42):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(43) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(43) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="42"] .cdp_i:nth-child(44):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(45):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="43"] .cdp_i:nth-child(42):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(43):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(44) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(44) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="43"] .cdp_i:nth-child(45):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(46):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="44"] .cdp_i:nth-child(43):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(44):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(45) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(45) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="44"] .cdp_i:nth-child(46):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(47):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="45"] .cdp_i:nth-child(44):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(45):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(46) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(46) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="45"] .cdp_i:nth-child(47):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(48):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="46"] .cdp_i:nth-child(45):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(46):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(47) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(47) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="46"] .cdp_i:nth-child(48):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(49):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="47"] .cdp_i:nth-child(46):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(47):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(48) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(48) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="47"] .cdp_i:nth-child(49):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(50):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="48"] .cdp_i:nth-child(47):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(48):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(49) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(49) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="48"] .cdp_i:nth-child(50):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(51):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="49"] .cdp_i:nth-child(48):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(49):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(50) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(50) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="49"] .cdp_i:nth-child(51):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(52):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="50"] .cdp_i:nth-child(49):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(50):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(51) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(51) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="50"] .cdp_i:nth-child(52):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(53):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="51"] .cdp_i:nth-child(50):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(51):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(52) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(52) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="51"] .cdp_i:nth-child(53):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(54):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="52"] .cdp_i:nth-child(51):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(52):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(53) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(53) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="52"] .cdp_i:nth-child(54):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(55):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="53"] .cdp_i:nth-child(52):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(53):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(54) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(54) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="53"] .cdp_i:nth-child(55):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(56):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="54"] .cdp_i:nth-child(53):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(54):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(55) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(55) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="54"] .cdp_i:nth-child(56):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(57):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="55"] .cdp_i:nth-child(54):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(55):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(56) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(56) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="55"] .cdp_i:nth-child(57):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(58):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="56"] .cdp_i:nth-child(55):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(56):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(57) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(57) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="56"] .cdp_i:nth-child(58):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(59):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="57"] .cdp_i:nth-child(56):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(57):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(58) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(58) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="57"] .cdp_i:nth-child(59):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(60):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="58"] .cdp_i:nth-child(57):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(58):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(59) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(59) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="58"] .cdp_i:nth-child(60):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(61):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="59"] .cdp_i:nth-child(58):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(59):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(60) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(60) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="59"] .cdp_i:nth-child(61):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(62):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="60"] .cdp_i:nth-child(59):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(60):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(61) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(61) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="60"] .cdp_i:nth-child(62):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(63):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="61"] .cdp_i:nth-child(60):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(61):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(62) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(62) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="61"] .cdp_i:nth-child(63):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(64):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="62"] .cdp_i:nth-child(61):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(62):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(63) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(63) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="62"] .cdp_i:nth-child(64):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(65):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="63"] .cdp_i:nth-child(62):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(63):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(64) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(64) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="63"] .cdp_i:nth-child(65):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(66):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="64"] .cdp_i:nth-child(63):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(64):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(65) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(65) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="64"] .cdp_i:nth-child(66):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(67):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="65"] .cdp_i:nth-child(64):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(65):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(66) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(66) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="65"] .cdp_i:nth-child(67):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(68):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="66"] .cdp_i:nth-child(65):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(66):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(67) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(67) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="66"] .cdp_i:nth-child(68):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(69):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="67"] .cdp_i:nth-child(66):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(67):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(68) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(68) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="67"] .cdp_i:nth-child(69):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(70):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="68"] .cdp_i:nth-child(67):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(68):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(69) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(69) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="68"] .cdp_i:nth-child(70):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(71):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="69"] .cdp_i:nth-child(68):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(69):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(70) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(70) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="69"] .cdp_i:nth-child(71):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(72):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="70"] .cdp_i:nth-child(69):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(70):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(71) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(71) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="70"] .cdp_i:nth-child(72):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(73):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="71"] .cdp_i:nth-child(70):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(71):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(72) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(72) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="71"] .cdp_i:nth-child(73):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(74):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="72"] .cdp_i:nth-child(71):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(72):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(73) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(73) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="72"] .cdp_i:nth-child(74):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(75):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="73"] .cdp_i:nth-child(72):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(73):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(74) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(74) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="73"] .cdp_i:nth-child(75):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(76):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="74"] .cdp_i:nth-child(73):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(74):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(75) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(75) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="74"] .cdp_i:nth-child(76):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(77):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="75"] .cdp_i:nth-child(74):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(75):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(76) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(76) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="75"] .cdp_i:nth-child(77):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(78):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="76"] .cdp_i:nth-child(75):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(76):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(77) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(77) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="76"] .cdp_i:nth-child(78):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(79):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="77"] .cdp_i:nth-child(76):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(77):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(78) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(78) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="77"] .cdp_i:nth-child(79):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(80):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="78"] .cdp_i:nth-child(77):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(78):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(79) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(79) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="78"] .cdp_i:nth-child(80):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(81):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="79"] .cdp_i:nth-child(78):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(79):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(80) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(80) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="79"] .cdp_i:nth-child(81):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(82):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[actpage="80"] .cdp_i:nth-child(79):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(80):not(:first-child) {
  display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(81) {
  background-color: #40b9ff;
  color: #fff;
  display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(81) + .cdp_i:last-child {
  display: none !important;
}
.cdp[actpage="80"] .cdp_i:nth-child(82):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(83):not(:last-child) {
  display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #40b9ff;
  font-size: 32px;
  margin-left: -6px;
}

.form-group {
  margin-bottom: 20px;
}
.form-group textarea,
.form-group input {
  width: 100%;
  background: rgba(255, 255, 255, 0.0705882353);
  border: none;
  border-radius: 30px;
  font-size: 16px;
  color: #fff;
  padding: 15px 20px;
  line-height: 1;
  height: 50px;
  display: block;
}
.form-group textarea:focus, .form-group textarea:hover, .form-group textarea:active,
.form-group input:focus,
.form-group input:hover,
.form-group input:active {
  color: #ffffff;
  border: 1px solid #FFBE18;
  outline: 0;
  box-shadow: none;
  border-radius: 25px;
}
.form-group textarea::placeholder,
.form-group input::placeholder {
  font-size: 16px;
  color: #aaabb2;
  line-height: 1;
}
.form-group textarea {
  min-height: 200px;
  max-height: 200px;
}

.icon-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px 21px;
  width: 294px;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.icon-card img {
  width: 60px;
  height: 60px;
}
.icon-card h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}
.icon-card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

.table.table-theme thead {
  background-color: rgba(41, 172, 246, 0.47);
}
.table.table-theme tbody tr td {
  font-size: 0.9rem;
  vertical-align: top;
}
.table thead tr th {
  font-size: 0.9rem;
}
.table-actions a {
  font-size: 1.3rem;
}

.table > :not(:first-child) {
  border-top: 0;
}

.va-middle td, .va-middle th {
  vertical-align: middle;
}

.table-add-sale .col-input {
  max-width: 70px;
}

.dropdown-table-actions > a {
  font-size: 1.3rem;
}
.dropdown-table-actions .dropdown-menu a {
  color: black;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}
.dropdown-table-actions .dropdown-menu a:hover i {
  color: #45F882;
}
.dropdown-table-actions .dropdown-menu a i {
  margin-right: 0.5rem;
}

.table-condensed thead tr th {
  font-size: 0.8rem;
}
.table-condensed tbody tr td {
  font-size: 0.8rem;
}

.table-sticky thead th {
  position: sticky;
  top: -1rem;
  background-color: #D9F0F9;
  z-index: 1;
}

.slick-slide {
  text-align: center;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  margin-bottom: 10px;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button::before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li .slick-active button::before {
  opacity: 0.75;
  color: rgb(255, 255, 255);
}

.offcanvas.offcanvas-end.show .accordion {
  border: transparent;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0.8rem;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item {
  border: transparent;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header {
  padding: 0.3rem 0;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: #cfe3fe;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed),
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header button {
  font-size: 0.9rem;
  border-radius: 0.8rem;
  color: rgb(0, 0, 0);
  font-weight: 500;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):hover, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):focus, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header .accordion-button:active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header button:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header button:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-header button:active {
  background-color: rgba(82, 189, 251, 0.4509803922);
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body {
  padding: 4px 10px;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu {
  list-style: none;
  margin: 0;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  border-radius: 0.8rem;
  color: black;
}
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a:hover, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a:focus, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a:active, .offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse.show .accordion-body .sub-menu a:target,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a:active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-item:last-of-type .accordion-collapse .accordion-body .sub-menu a:target,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a:active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapsing .accordion-body .sub-menu a:target,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a:hover,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a:focus,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a:active,
.offcanvas.offcanvas-end.show .accordion .accordion-item .accordion-collapse.collapse .accordion-body .sub-menu a:target {
  background-color: rgba(82, 189, 251, 0.4509803922);
}

.btn-close {
  z-index: 50;
}

.toggle-side-menu {
  display: none;
}

@media screen and (max-width: 991px) {
  .top-link {
    display: none;
  }
  .toggle-side-menu {
    display: block;
    z-index: 10;
  }
  .toggle-side-menu a i {
    color: black;
    font-size: 30px !important;
  }
}
.scrollbar {
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(53, 176, 247, 0.4509803922);
  border-radius: 20px;
}
.scrollbar::-moz-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-moz-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-moz-scrollbar-thumb {
  background-color: rgba(53, 176, 247, 0.4509803922);
  border-radius: 20px;
}
.scrollbar::-ms-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-ms-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-ms-scrollbar-thumb {
  background-color: rgba(53, 176, 247, 0.4509803922);
  border-radius: 20px;
}
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(53, 176, 247, 0.4509803922) rgba(0, 0, 0, 0.1);
}

@keyframes mdp-float-aura {
  from {
    box-shadow: 0 0 0 -1px var(--hmp-color-75), 0 0 0 -1px var(--hmp-color-50), 0 0 0 -1px var(--hmp-color-25);
  }
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0), 0 0 0 30px rgba(255, 255, 255, 0), 0 0 0 45px rgba(255, 255, 255, 0);
  }
}
@keyframes mdp-float-init {
  0% {
    opacity: 0;
    transform: scale(0.25);
  }
  50% {
    opacity: 0;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.mdp-float {
  --hmp-color: rgb(51, 153, 255);
  --hmp-color-75: rgba(51, 153, 255, .75);
  --hmp-color-50: rgba(51, 153, 255, .5);
  --hmp-color-25: rgba(51, 153, 255, .25);
  --hmp-color-5: rgba(51, 153, 255, .05);
  position: absolute;
  z-index: 999999;
  border-radius: 50%;
  top: 3em;
  right: 3em;
  width: 3em;
  height: 3em;
  transition: 0.75s;
  transition-timing-function: ease-out;
}

.mdp-float:hover {
  animation: mdp-float-aura infinite;
  animation-duration: 2s;
}

.mdp-float.mdp-float-inertia {
  transition: 0.25s;
}

.mdp-float.mdp-float-slow {
  transition-delay: 0.5s;
  transition: 5s;
}

.mdp-float.mdp-float-init {
  animation: mdp-float-init 1s;
  animation-fill-mode: both;
}

.mdp-float-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  text-shadow: 1px 0 0 var(--hmp-color);
  background: linear-gradient(210deg, var(--hmp-color-5) 0, var(--hmp-color-25) 100%);
  background-color: #fff;
  transition: 0.25s;
  box-shadow: 0 7px 13px -3px var(--hmp-color-25), 0 2px 4px 0 var(--hmp-color-50), inset 0 0 0 0 var(--hmp-color);
  position: relative;
}

.mdp-float-btn:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--hmp-color);
  border-radius: 50%;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

.mdp-float-btn svg {
  width: 1em;
  height: 1em;
  fill: var(--hmp-color);
  z-index: 2;
}

.mdp-float-btn:focus {
  outline: 0;
}

.mdp-float-btn:hover {
  transition: 0.25s;
  transition-delay: 125ms;
  box-shadow: 0 7px 26px -3px var(--hmp-color-25), 0 2px 8px 0 var(--hmp-color-50);
  border: none;
}

.mdp-float-btn:hover:before {
  transform: scale(1);
}

.mdp-float-btn:hover svg {
  transform: scale(1.1);
  fill: #fff;
}

.mdp-float-btn:active {
  transform: scale(0.75);
}

@media (max-width: 720px) {
  .mdp-float {
    top: 1.5em;
    right: 1.5em;
  }
}
.nav-tabs {
  justify-content: start;
  display: flex;
  border-bottom: none;
}
.nav-tabs .nav-item {
  color: black !important;
}
.nav-tabs .nav-item .nav-link {
  border-color: transparent;
  background: rgb(255, 255, 255);
  border: 1px solid transparent;
  color: rgb(0, 0, 0);
  border-radius: 20px;
  margin: 10px;
}
.nav-tabs .nav-item:focus, .nav-tabs .nav-item:active {
  background: transparent;
  border-radius: 1px;
}
.nav-tabs .nav-item .active {
  color: rgb(255, 255, 255);
  background: linear-gradient(225deg, #ffdb33 0.42%, #ffdb33 86.12%);
}
.nav-tabs .nav-item .button#controlled-tab-example-tab-home {
  color: rgba(59, 51, 53, 0);
}

.tab-responsive {
  row-gap: 40px;
  column-gap: 20px;
}
.tab-responsive .tab-img {
  width: 300px;
  height: auto;
  border-radius: 8px;
}

.progress,
.progress-stacked {
  --bs-progress-height: 8px;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}
.progress .bg-success,
.progress-stacked .bg-success {
  width: 40%;
  --bs-bg-opacity: 1;
  background-color: #FFBE18 !important;
}
.progress .progress-bar,
.progress-stacked .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: #FFBE18;
  transition: var(--bs-progress-bar-transition);
}

.slick {
  background: #000;
  width: 100%;
  height: 100%;
  padding: 30px;
}
.slick .section-title {
  color: #fff !important;
}

.our-progress-bar {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
  margin-top: 100px;
}
.our-progress-bar li {
  width: 100%;
}
.our-progress-bar label {
  color: #fff;
  margin-bottom: 20px;
  font-size: 1.3rem;
}

.instruction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.instruction .profile {
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 124, 195, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 5px 48px;
  position: relative;
  width: 348px;
  height: 430px;
  max-width: 348px;
  min-width: 348px;
}
.instruction .profile img {
  border-radius: 17px;
}
.instruction .profile .social-link {
  bottom: -30px;
}
.instruction .massage {
  color: rgba(0, 0, 0, 0.5);
}
.instruction .massage h1 {
  font-weight: 800;
  font-size: 35px;
  line-height: 146.02%;
  color: #FFBE18;
}
.instruction .massage p {
  font-weight: 400;
  font-size: 14px;
  line-height: 146.02%;
}
.instruction .massage h3 {
  font-weight: 800;
  font-size: 14px;
  line-height: 146.02%;
  color: #FFBE18;
}
.instruction .massage span {
  font-weight: 400;
  font-size: 16px;
  line-height: 135.52%;
}

@media screen and (max-width: 992px) {
  .instruction {
    flex-direction: column;
  }
  .instruction .profile {
    height: 341px;
    min-width: 273px;
    max-width: 273px;
  }
  .instruction .profile img {
    width: 244px;
  }
  .instruction .profile .social-link {
    width: 165px;
  }
  .instruction .profile .social-link .social-list .link {
    margin: 0px 7px;
    width: 35px;
    height: 35px;
  }
  .instruction .massage {
    text-align: center;
  }
}
.bog-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 124, 195, 0.25);
  border-radius: 30px;
  width: 274px;
  height: 348px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  margin: auto;
  padding: 10px;
}
.bog-card::after {
  content: "";
  width: 0;
  height: 8px;
  border-radius: 0.2rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(225deg, #ffdb33 0.42%, #ffdb33 86.12%);
  transition: width 200ms ease-in;
}
.bog-card:hover::after, .bog-card:focus::after {
  width: 100%;
}
.bog-card .model {
  position: relative;
  margin-bottom: 27px;
  margin-top: 17px;
}
.bog-card .model .profile {
  width: 155px;
  height: 155px;
  border: 1px solid #FFBE18;
  border-radius: 100px;
}
.bog-card .model .crical {
  display: none;
  position: absolute;
  left: 8px;
  top: 8px;
  margin: auto;
}
.bog-card h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFBE18;
}
.bog-card p {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}
.bog-card .social {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
}
.bog-card .social li {
  list-style: none;
  padding: 0px 0.7rem;
}
.bog-card .social li a {
  color: #FFBE18;
}
.bog-card .social li a i {
  font-size: 24px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
}

.overlay {
  background-color: #ecf0f3;
}

.modal-content {
  position: fixed;
  top: 18px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
  min-width: 90%;
  max-height: 435px;
  padding: 10px;
  border-radius: 10px;
  flex-direction: column;
}
.modal-content .modal-header .modal-title {
  font-size: 15px;
  font-weight: 600;
  color: #007cc3;
}
.modal-content .modal-body {
  margin-top: 10px;
  height: 334px;
  min-height: 334px;
  max-height: 334px;
  overflow-y: scroll;
}
.modal-text {
  padding: 0 100px 0 50px;
}
.modal-text h1 {
  margin: 20px 0 20px 0;
  font-size: 30px;
}
.modal-text p {
  font-size: 17px;
  margin-bottom: 20px;
  line-height: 25px;
}
.modal-text button {
  margin-right: 20px;
  color: #ff014f;
}
.modal-text button i {
  margin-left: 10px;
}
.modal-text button.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  padding: 0 0;
  font-size: 20px;
}
.modal-text button.close-modal i {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .modal,
  .overlay {
    overflow: scroll;
  }
  .modal .modal-img {
    margin: 50px 0 50px 0;
  }
  .modal .right,
  .modal .left {
    padding: 0;
  }
}
.countdownlist {
  margin: 30px 0;
}
.countdownlist ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 50px;
}
.countdownlist ul li span {
  font-size: 32px;
  font-weight: bold;
}
.countdownlist ul li p {
  margin: 0;
  color: #007cc3;
  font-size: 17px;
  font-weight: 600;
}

.page-heading-banner {
  position: relative;
}
.page-heading-banner img {
  width: 100%;
  height: auto;
}
.page-heading-banner h3 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  background: #FFBE18;
  padding: 7px 16px;
}

.page-contant ul {
  margin: 20px;
}
.page-contant ul li::marker {
  color: #FFBE18;
}
.page-contant h3 {
  font-weight: 600;
  font-size: 1.5rem;
  position: relative;
  margin-bottom: 23px;
}
.page-contant h3 span {
  padding-left: 15px;
  color: #FFBE18;
}
.page-contant h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 2px;
  background-color: #FFBE18;
}

@media screen and (max-width: 767px) {
  .page-heading-banner h3 {
    top: 47%;
    left: 23%;
    color: white;
    font-size: 0.8rem;
  }
  .page-contant ul {
    margin: 20px 0;
  }
  .page-contant ol {
    padding-left: 18px;
  }
}
.pump-logos {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pump-logos li img {
  max-width: 170px;
  display: inline-block;
  margin: 10px;
}

.reports {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.reports li {
  background-color: #FFF;
  border-radius: 0.4rem;
  padding: 2rem;
  text-align: center;
  flex-basis: 17%;
  margin: 1%;
  justify-content: center;
  box-shadow: 0px 11px 32px rgba(0, 128, 249, 0.08);
}
.reports li p {
  font-weight: bolder;
  font-size: 1.4rem;
  max-width: 110px;
  margin: 0 auto 1.5rem auto;
  line-height: 1.8rem;
}
.reports li .button-sm {
  padding: 0.5rem;
  font-size: 0.8rem;
}

.features_2 {
  padding: 3rem 0;
}
.features_2 .feature_box_2 {
  background-color: #FFF;
  box-shadow: 0px 11px 32px rgba(0, 128, 249, 0.08);
  border-radius: 25px;
  padding: 2rem;
  text-align: center;
}
.features_2 .feature_box_2 .icon {
  margin-bottom: 1rem;
}
.features_2 .feature_box_2 .icon span {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
  background-color: rgba(242, 141, 32, 0.2);
  color: #F28D20;
  font-size: 2rem;
}
.features_2 .feature_box_2 h3 {
  font-size: 1.2rem;
}
.features_2 .feature_box_2 p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
}

.cta {
  background-color: #061956;
  padding: 5rem 0;
}
.cta .cta-content {
  text-align: center;
  color: #FFF;
}
.cta .cta-content h3 {
  font-size: 2.5rem;
  margin-bottom: 1.4rem;
}
.cta .cta-content p {
  font-size: 1.2rem;
  font-weight: 300;
}
.cta .cta-content .cta-buttons {
  margin-top: 2rem;
}
.cta .cta-content .cta-buttons .button {
  margin: 0.5rem;
}

.c-icon-box {
  display: flex;
}
.c-icon-box .icon {
  flex-basis: 20%;
  padding-right: 1rem;
}

.contact-box {
  display: flex;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 1.5rem;
  border-radius: 0.4rem;
  min-height: 150px;
}
.contact-box .icon {
  padding-right: 1rem;
}
.contact-box .icon span {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFBE18;
  color: #FFF;
  font-size: 2.4rem;
  border-radius: 50%;
}

.map iframe {
  border-radius: 0.5rem;
}

.blog {
  padding: 10px;
}
.blog img {
  width: 100%;
  border-radius: 20px;
  height: 200px;
  object-fit: cover;
}
.blog .date {
  color: #fff;
  opacity: 0.7;
}
.blog .content {
  padding: 20px;
}

.document-area {
  position: relative;
  z-index: 2;
}

.document-form-wrap {
  background: #0F101E;
  border: 1px solid rgba(43, 44, 56, 0.46);
  padding: 60px 50px 50px;
  text-align: center;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.document-wrap {
  background: #0F101E;
  border: 1px solid rgba(43, 44, 56, 0.46);
  padding: 60px 64px 50px;
  border-radius: 15px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.document-wrap .title {
  margin-bottom: 35px;
  font-size: 28px;
}
.document-wrap .list-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 25px;
}
.document-wrap .list-wrap a {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
}
.document-wrap .list-wrap a:hover {
  color: #FFBE18;
}
.document-wrap .list-wrap a .icon {
  width: 130px;
  height: 104px;
  background: rgba(255, 255, 255, 0.0705882353);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 35px;
  line-height: 0;
  margin-bottom: 5px;
}
.document-wrap .btn {
  margin-top: 20px;
}

.document-shape img {
  position: absolute;
  right: 3%;
  top: -2%;
  z-index: -1;
}

.download-area {
  padding-top: 130px;
  padding-bottom: 130px;
}

.download-inner-wrap {
  background: #0F101E;
  border-radius: 15px;
  border: 1px solid rgba(43, 44, 56, 0.46);
  padding: 75px 80px 0;
  position: relative;
  z-index: 1;
}

.download-content {
  padding: 40px 0 115px;
}
.download-content .section-title {
  margin-bottom: 40px;
}
.download-content .section-title .title {
  font-size: 32px;
  color: #fff;
}

.download-list .list-wrap {
  display: flex;
  align-items: center;
  gap: 15px 50px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.download-list .list-wrap li {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  gap: 10px;
}
.download-list .list-wrap li span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background: #1a1c2b;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0, 20, 90, 0.1);
  font-size: 16px;
  color: #fff;
}

.download-btn-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.download-btn-wrap .download-btn img {
  max-height: 60px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: auto;
  height: auto;
}

.download-img {
  position: relative;
  z-index: 1;
  text-align: right;
}
.download-img img {
  height: auto;
}
.download-img img:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.download-shape-wrap img {
  position: absolute;
  z-index: -1;
  height: auto;
}
.download-shape-wrap img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.download-shape-wrap img:nth-child(2) {
  right: 0;
  bottom: 0;
}

.document-area {
  position: relative;
  z-index: 2;
}

.features-area {
  padding-top: 140px;
  padding-bottom: 110px;
}
.features-area .section-title {
  margin-bottom: 70px;
}
.features-area .section-title .title {
  font-size: 32px;
  color: #fff;
}
.features-area .features-item {
  display: flex;
  align-items: center;
  background: #0F101E;
  border: 1px solid rgba(43, 44, 56, 0.46);
  border-radius: 15px;
  padding: 40px 70px 40px 55px;
  margin-bottom: 30px;
}
.features-area .features-item .features-content {
  width: 50%;
  margin-right: 20px;
}
.features-area .features-item .features-content .title {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.84px;
  margin-bottom: 25px;
}
.features-area .features-item .features-content .title a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
}
.features-area .features-item .features-content .title a:hover {
  color: var(--tg-primary-color);
}
.features-area .features-item .features-content p {
  margin-bottom: 0;
  color: #fff;
}
.features-area .features-item .features-img {
  flex-grow: 1;
  text-align: right;
}
.features-area .features-item .features-img img {
  width: 150px;
  height: auto;
}
.features-area .features-bg {
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
  position: relative;
  margin: 0 20px;
  overflow: hidden;
}
.features-area .features-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #FFBE18 0%, #45F882 47.6%, #000 65.23%);
  z-index: -1;
  transform: rotate(180deg);
}
.features-area .features-inner-wrap {
  position: relative;
  padding: 140px 0;
  z-index: 1;
}
.features-area .features-line-shape {
  width: 100%;
  height: 2px;
  background: linear-gradient(225deg, #010314, rgba(255, 255, 255, 0.4509803922) 54.98%, rgba(1, 3, 20, 0.9882352941));
  position: absolute;
  left: 0;
  bottom: 0;
}
.features-area .features-item-wrap {
  margin-bottom: 90px;
}
.features-area .features-item-two {
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1411764706), rgba(255, 255, 255, 0));
  box-shadow: 0 16px 18px rgba(1, 12, 50, 0.1);
  display: flex;
  align-items: center;
  padding: 30px;
  margin-bottom: 30px;
  transition: transform 0.3s linear;
}
.features-area .features-item-two:hover {
  transform: translateY(-5px);
}
.features-area .features-item-two .features-img-two {
  width: 95px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.features-area .features-item-two .features-img-two img {
  height: auto;
}
.features-area .features-item-two .features-content-two .title {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.72px;
  color: #fff;
}

.faq-inner-wrap {
  padding: 140px 0;
  position: relative;
}
.faq-inner-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(225deg, #010314, rgba(255, 255, 255, 0.4509803922) 54.98%, rgba(1, 3, 20, 0.9882352941));
}

.faq-wrap {
  margin: 0 30px;
}
.faq-wrap .accordion-item {
  background-color: #0F101E;
  border: 1px solid rgba(43, 44, 56, 0.46);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}
.faq-wrap .accordion-item.active {
  background: linear-gradient(0deg, #45F882 0%, #0F101E 47.6%, #000 98.23%);
  border: none;
}
.faq-wrap .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.faq-wrap .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.faq-wrap .accordion-item .accordion-button {
  padding: 38px 90px 38px 55px;
  font-size: 24px;
  color: #fff;
  background-color: transparent;
  font-weight: 600;
  letter-spacing: -0.48px;
  position: relative;
}
.faq-wrap .accordion-item .accordion-button:after {
  flex-shrink: 0;
  content: "↑";
  background-image: none;
  font-size: 30px;
  font-weight: 600;
  position: absolute;
  right: 45px;
  top: 38px;
  color: #fff;
  opacity: 0.3;
}
.faq-wrap .accordion-item .accordion-button:not(.collapsed):after {
  background-image: none;
  opacity: 1;
}
.faq-wrap .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: transparent;
  box-shadow: none;
}
.faq-wrap .accordion-item .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.faq-wrap .accordion-item .accordion-body {
  padding: 0 55px 70px;
}
.faq-wrap .accordion-item .accordion-body p {
  font-size: 18px;
  margin-bottom: 0;
  color: #cbd0d4;
}

.rating-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.rating-card .starts span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #FFBE18;
  font-size: 30px;
}
.rating-card .section-detail {
  text-align: center;
  max-width: 600px;
  color: #fff;
}
.rating-card .profile {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.rating-card .profile .user-detail {
  text-align: center;
  margin-top: 10px;
}
.rating-card .profile .user-detail .user_name {
  color: #fff;
  font-weight: 600;
  font-size: 1.4rem;
}
.rating-card .profile .user-detail .job {
  color: #fff;
  font-weight: 500;
}
.rating-card .profile .user {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 30px;
}
.rating-card .profile .user img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.partnerslider .partner {
  background-color: rgba(0, 0, 0, 0.1);
  width: 240px !important;
  margin: auto !important;
}
.partnerslider .partner:hover img {
  filter: grayscale(0);
  opacity: 1;
}
.partnerslider .partner img {
  width: 95%;
  filter: grayscale(100%);
  opacity: 0.8;
  transition: all 0.5s ease;
}

.why-page h1 {
  color: #fff;
  font-size: 20px;
}

.why-card {
  padding: 40px;
  border-radius: 15px;
  min-height: 350px;
  border: 2px solid #fff;
  position: relative;
  background: #0F101E;
  border: 1px solid rgba(43, 44, 56, 0.46);
}
.why-card:hover {
  border: 2px solid #FFBE18;
  transition-duration: 1s;
}
.why-card h3 {
  font-weight: bold;
  color: #FFBE18;
}
.why-card p {
  font-size: 16px;
  color: #fff;
}
.why-card .why-img {
  height: 100px;
  position: absolute;
  bottom: 20px;
  right: 50px;
}

@media screen and (max-width: 767px) {
  .banner-bg {
    padding: 100px 0 50px;
    min-height: 100%;
  }
  .banner-bg img {
    width: 50%;
  }
  .banner-content {
    position: relative;
    z-index: 1;
  }
  .banner-content .title {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .banner-content p {
    font-size: 18px;
    margin-bottom: 50px;
    line-height: 20px;
  }
  .banner-shape-wrap {
    display: none;
  }
  .download-inner-wrap {
    padding: 20px 20px 0;
  }
  .document-form-wrap {
    padding: 20px;
  }
}
.banner {
  background-size: cover;
  background-position: center;
  border-radius: 0 0 20px 20px;
  position: relative;
  padding: 140px 0 120px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin: 0 20px;
}
.banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #45F882 0%, #c79707 47.6%, transparent 65.23%);
  z-index: -1;
  border-radius: 0 0 20px 20px;
}

.banner-content {
  position: relative;
  z-index: 1;
}
.banner-content .title {
  font-size: 58px;
  margin-bottom: 45px;
  font-weight: 700;
  color: #fff;
}
.banner-content .title span {
  background: linear-gradient(180deg, #fff 0%, #45F882 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banner-content p {
  font-size: 20px;
  margin-bottom: 55px;
  color: #fff;
  font-weight: 500;
}

.banner-scroll-down {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 15px;
}
.banner-scroll-down a {
  color: #fff;
  display: block;
  width: 30px;
  height: 50px;
  text-align: center;
}
.banner-scroll-down a span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  margin: -10px 0 0 8px;
  animation: animate 2s infinite;
}
.banner-scroll-down a span:nth-child(2) {
  animation-delay: -0.2s;
}
.banner-scroll-down a span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}

.banner-shape-wrap img {
  position: absolute;
}

.banner-shape-wrap img:nth-child(1) {
  left: 107px;
  bottom: 134px;
}

.banner-shape-wrap img:nth-child(2) {
  right: 107px;
  bottom: 120px;
}

.banner-shape-wrap img {
  position: absolute;
}

.banner-shape-wrap img:nth-child(1) {
  left: 107px;
  bottom: 134px;
}

.banner-shape-wrap img:nth-child(2) {
  right: 107px;
  bottom: 120px;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0) translateY(0);
  }
  50% {
    transform: rotateX(0) translateY(-30px);
  }
  to {
    transform: rotateX(0) translateY(0);
  }
}
.alltuchtopdown {
  -webkit-animation-name: alltuchtopdown;
  animation-name: alltuchtopdown;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes leftToRight {
  0% {
    transform: rotateX(0) translate(0);
  }
  50% {
    transform: rotateX(0) translate(50px);
  }
  to {
    transform: rotateX(0) translate(0);
  }
}
.leftToRight {
  -webkit-animation-name: leftToRight;
  animation-name: leftToRight;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.blog-item {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.blog-item img {
  border-radius: 10px;
  width: 100%;
  transition: all 0.5s ease;
}
.blog-item:hover img {
  transform: scale(1.03);
}
.blog-item span {
  position: absolute;
  left: 44px;
  text-align: center;
  background-color: #FFBE18;
  display: none;
  color: #FFF;
  padding: 10px 18px;
  border-radius: 0 0 22px 22px;
  font-weight: bold;
}
.blog-item .content {
  padding: 32px;
  min-height: 289px;
}
.blog-item .content h6 {
  color: #FFBE18;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.blog-item .content h3 {
  font-size: 20px;
  font-weight: 700;
}
.blog-item .content p {
  color: rgb(104, 119, 153);
  margin-top: 11px;
  font-size: 14px;
  font-weight: 500;
  min-height: 120px;
}
.blog-item .content a {
  color: rgb(104, 119, 153);
  font-size: 12px;
  font-weight: bold;
}
.blog-item .content a:hover {
  color: #FFBE18;
}

.media-content-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.media-content-list li {
  position: relative;
  padding-left: 32px;
}
.media-content-list li > i {
  position: absolute;
  left: 0;
  top: -3px;
  color: #FFBE18;
  font-size: 20px;
}
.media-content-list li h3 {
  font-size: 16px;
  font-weight: bold;
}

.section-div {
  background-color: #F0F2F5;
  position: relative;
}
.section-div .container .row .col-md-6 .form-div {
  margin: 37px;
  margin-top: 130px;
}
.section-div .container .row .col-md-6 .form-div .paragraph {
  margin-top: 30px;
  margin-bottom: 60px;
  font-size: 17px;
}
.section-div .container .row .col-md-6 .form-div .custom-size {
  max-width: 70%;
}
.section-div .container .row .col-md-6 .form-div .form-control {
  border-radius: 0;
  background-color: #F0F2F5;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}
.section-div .container .row .col-md-6 .form-div p {
  font-size: 11px;
}
.section-div .container .row .col-md-6 .form-div button {
  padding: 13px 60px 13px 60px;
  border: none;
  background-color: #165397;
  color: white;
}
.section-div .container .row .col-md-6 .form-div button:hover {
  background-color: #45F882;
}
.section-div .container .row .col-md-6 .content .container .row .col-md-6 h1 {
  font-weight: 600;
  font-size: 19px;
}
.section-div .container .row .col-md-6 .content .container .row .col-md-6 p {
  line-height: 25px;
  font-size: 13px;
  color: #6A6A6A;
}
.section-div .map-section {
  width: 50vw;
  top: 0;
  position: absolute;
  right: 0;
  height: 100%;
}
.section-div .map-section iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .section-div .container .row .col-md-6 .form-div {
    margin: 20px;
    margin-top: 50px;
  }
  .section-div .container .row .col-md-6 .form-div .paragraph {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .section-div .container .row .col-md-6 .form-div .custom-size {
    max-width: 90%;
  }
  .section-div .container .row .col-md-6 .form-div button {
    padding: 10px 40px;
  }
  .section-div .map-section {
    width: 100%;
    position: relative;
    height: 300px;
  }
  .section-div .map-section iframe {
    width: 100%;
    height: 100%;
  }
}

