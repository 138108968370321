.page-heading-banner {
    position: relative;

    img {
        width: 100%;
        height: auto;
    }

    h3 {
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        color: white;
        font-size: 1.8rem;
        font-weight: 600;
        background: $primary;
        padding: 7px 16px;
    }

}

.page-contant {
    ul {
        margin: 20px;

        li {
            &::marker {
                color: $primary;
            }
        }
    }

    h3 {
        font-weight: 600;
        font-size: 1.5rem;
        position: relative;
        margin-bottom: 23px;

        span {
            padding-left: 15px;
            color: $primary;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 20px;
            height: 2px;
            background-color: $primary;
        }
    }
}

@media screen and (max-width : 767px) {
    .page-heading-banner {
        h3 {
            top: 47%;
            left: 23%;
            color: white;
            font-size: .8rem;
        }
    }
    .page-contant {
        ul {
            margin: 20px 0;
        }
        ol {
            padding-left: 18px;
        }
    }
}