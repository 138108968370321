.footer-bg {
    background-size: cover;
    background-position: center;
    border-radius: 20px 20px 0 0;
    position: relative;
    padding: 150px 0 0;
    margin: -200px 20px 0;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #20212e;
        z-index: -2;
        transform: rotate(180deg);
    }
}

.footer-top {
    padding: 110px 0 30px;
    z-index: 2;
    position: relative;
}

.footer-widget {
    margin-bottom: 40px;

    .fw-title {
        margin-bottom: 15px;
        font-size: 18px;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
    }
}

.footer-link {
    .list-wrap {
        list-style-type: none;

        li {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: #ffffff;
                font-size: 15px;
                font-weight: 500;
                line-height: 1.3;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background: #fff;
                    transform-origin: right top;
                    transform: scaleX(0);
                    transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
                }

                &:hover:before {
                    transform-origin: left top;
                    transform: scale(1);
                }
            }
        }
    }
}

.footer-newsletter {
    p {
        margin-bottom: 20px;
        font-size: 15px;
        color: #cbd0d4;
        font-weight: 500;
    }

    .form-group {
        position: relative;
        margin-bottom: 0;

        input {
            border: 1px solid #000;
            background: #0F101E;
        }

        button {
            line-height: 1;
            border: none;
            padding: 0;
            position: absolute;
            right: 13px;
            top: 25%;
            font-size: 30px;
            color: $primary;
            background: transparent;
        }
    }
}

.footer-bottom {
    padding: 30px 0;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(225deg, #fff 0%, rgba(255, 255, 255, 0.45) 54.98%, rgb(255 190 24) 100%);
    }
}

.copyright-text {
    p {
        margin-bottom: 0;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
    }
}
