.section-div {
    background-color: $lite;
    position: relative;

    .container {
        .row {
            .col-md-6 {

                .form-div {
                    margin: 37px;
                    margin-top: 130px;

                    .paragraph {
                        margin-top: 30px;
                        margin-bottom: 60px;
                        font-size: 17px;
                    }

                    .custom-size {
                        max-width: 70%;
                    }

                    .form-control {

                        border-radius: 0;
                        background-color: #F0F2F5;
                        border: none;
                        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
                    }

                    p {
                        font-size: 11px;
                    }

                    button {
                        padding: 13px 60px 13px 60px;
                        border: none;
                        background-color: $blue;
                        color: white;

                        &:hover {
                            background-color: $secondary;
                        }
                    }

                }

                .content {
                    .container {
                      .row {
                        .col-md-6 {
                
                          h1 {
                            font-weight: 600;
                            font-size: 19px;
                          }
                  
                          p {
                            line-height: 25px;
                    font-size: 13px;
                    color: $litegray;
                          }
                        }
                      }
                    }
                  }
            }
        }
    }

    .map-section {
        width: 50vw;
        top: 0;
        position: absolute;
        right: 0;
        height: 100%;
        iframe{
            width: 100%;
            height: 100%;
        }

    }
}









@media (max-width: 767px) {
    .section-div .container .row .col-md-6 .form-div {
        margin: 20px;
        margin-top: 50px;

        .paragraph {
            margin-top: 20px;
            margin-bottom: 40px;
        }

        .custom-size {
            max-width: 90%;
        }

        button {
            padding: 10px 40px;
        }
    }

    .section-div .map-section {
        width: 100%;
        position: relative;
        height: 300px;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
}





