.instruction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .profile {
        background: #FFFFFF;
        box-shadow: 0px 0px 16px rgba(0, 124, 195, 0.25);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 5px 48px;
        position: relative;
        width: 348px;
        height: 430px;
        max-width: 348px;
        min-width: 348px;

        img {
            border-radius: 17px;
        }

        .social-link {
            bottom: -30px;
        }

    }



    .massage {
        color: $gray;


        h1 {
            font-weight: 800;
            font-size: 35px;
            line-height: 146.02%;
            color: $primary;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 146.02%;
        }

        h3 {
            font-weight: 800;
            font-size: 14px;
            line-height: 146.02%;
            color: $primary;
        }

        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 135.52%;
        }
    }
}






@media screen and (max-width : 992px) {
    .instruction {

        flex-direction: column;

        .profile {
            height: 341px;
            min-width: 273px;
            max-width: 273px;

            img {
                width: 244px;
            }

            .social-link {
                width: 165px;

                .social-list {
                    .link {
                        margin: 0px 7px;
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }

        .massage {
            text-align: center;
        }
    }
}
